import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpEvent, HttpEventType ,HttpParams, HttpResponse} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import 'rxjs/add/observable/throw';
import 'rxjs/Rx';

import { MessageService } from './message.service';
//import { createInjectionToken } from '@angular/compiler/src/core';


@Injectable({
  providedIn: 'root',
})
export class DataService {

  enableLog: boolean;

  constructor(public http: HttpClient, public messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router) { this.enableLog = true; }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  public handleError<T> (operation = 'operation', result?: T) {
    return (response: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      this.error(response);

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${response.message}`);

      // Let the app keep running by returning an empty result.
      // return of(result as T);
      return of(response.error as T);
    };
  }

  public error(error: any) {
    if (this.enableLog) {
      console.log(error);
      if(error.status === 403) {

        // this.router.navigate(['/signin']);
        sessionStorage.removeItem('g-retail-token');
        sessionStorage.removeItem('currentUser');
        window.location.replace("/");
      }
    }
  }

  public log(message: any) { if (this.enableLog) console.log(message); }

  public logController(message: string): void { if (this.enableLog) console.log(message); }

  userAuth(url: string, user: string, pass: string) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    const body = new HttpParams()
      .set('email', user)
      .set('password', pass);
    return this.http.post(url, body.toString(), { headers, observe: 'response' })
      .pipe(tap((res: HttpResponse<any>) => this.log('fetched data')))
      .catch((err: any) => Observable.of(false));
  }

  chainOpt(url: string, body: any, opt: string){
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    let params: any
    if(opt === 'add'){
      params = new HttpParams()
        .set('name', body.name)
        .set('name_brand', body.brand_name)
        .set('file_name', body.file_name)
        .set('created_by', body.user_id)
        .set('name_for_display', body.name_for_display);

      return this.http.post(url, params.toString(), { headers, observe: 'response' })
        .pipe(tap((res: HttpResponse<any>) => this.log('fetched data')))
        .catch((err: any) => Observable.of(false));
    }
    else if(opt === 'edit'){
      params = new HttpParams()
        .set('id', body.id)
        .set('name', body.name)
        .set('name_brand', body.brand_name)
        .set('file_name', body.file_name)
        .set('updated_by', body.updated_by)
        .set('chain_no', body.chain_no)
        .set('name_for_display', body.name_for_display);

      return this.http.put(url, params.toString(), { headers, observe: 'response' })
        .pipe(tap((res: HttpResponse<any>) => this.log('fetched data')))
        .catch((err: any) => Observable.of(false));
    }
    else if(opt === 'delete'){
      //console.log('BODY :: ' + JSON.stringify(body))
      params = new HttpParams()
        .set('id', body.id)
        .set('chain_no', body.chain_no)
        .set('deleted_by', body.deleted_by);

      return this.http.post(url, params.toString(), { headers, observe: 'response' })
        .pipe(tap((res: HttpResponse<any>) => this.log('fetched data')))
        .catch((err: any) => Observable.of(false));
    }
  }

  fileUpload(url: string, body: any, file : any){

    const formData: FormData = new FormData()

    formData.append('source_id', body.source_id)
    formData.append('files', file)
    formData.append('created_by', body.created_by)
    formData.append('file_description', body.file_description)

    const req = new HttpRequest('POST', url, formData, {
      reportProgress: true,
    });

    return this.http.request(req);

  }

  userOpt(url: string, body: any, opt: string){
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    let params: any
    console.log("userOpt")
    if(opt === 'add'){
      params = new HttpParams()
        .set('mail_address', body.mail_address)
        .set('passwd', body.passwd)
        .set('role', body.role)
        .set('type', body.type)
        .set('name', body.name)
        .set('company', body.company)
        .set('company_tel', body.company_tel)
        .set('cell_no', body.cell_no)
        .set('icon', body.icon)
        .set('g_account', body.g_account)
        .set('accnt_expire', body.accnt_expire)
        .set('expire', body.expire)
        .set('notes', body.notes)
        .set('user_id', body.created_by)
        .set('vender', body.vender);

      return this.http.post(url, params.toString(), { headers, observe: 'response' })
        .pipe(tap((res: HttpResponse<any>) => this.log('fetched data')))
        .catch((err: any) => Observable.of(false));
    }
    else if(opt === 'edit'){
      console.log(body)
      console.log(url)
      params = new HttpParams()
      .set('mail_address', body.mail_address)
      .set('role', body.role)
      .set('passwd', body.passwd)
      .set('type', body.type)
      .set('name', body.name)
      .set('company', body.company)
      .set('company_tel', body.company_tel)
      .set('cell_no', body.cell_no)
      .set('icon', body.icon)
      .set('g_account', body.g_account)
      .set('accnt_expire', body.accnt_expire)
      .set('expire', body.expire)
      .set('notes', body.notes)
      .set('user_no', body.user_no)
      .set('updated_by', body.updated_by)
      .set('vender', body.vender);
      if(body.passwd) params.set('passwd', body.passwd);
      return this.http.put(url, params.toString(), { headers, observe: 'response' })
        .pipe(tap((res: HttpResponse<any>) => this.log('fetched data')))
        .catch((err: any) => Observable.of(false));
    }
    else if(opt === 'delete'){
      console.log('Delete BODY :: ' + JSON.stringify(body))
      params = new HttpParams()
        .set('user_no', body.user_no)
        .set('deleted_by', body.deleted_by);

      return this.http.post(url, params.toString(), { headers, observe: 'response' })
        .pipe(tap((res: HttpResponse<any>) => this.log('fetched data')))
        .catch((err: any) => Observable.of(false));
    }
  }

  storeMaintenance(url: string, body: any){
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    let params: any
      params = new HttpParams()
      .set('user_id' ,body.user_id  )
      .set('store_id',body.store_id )
      .set('company_name',body.company_name )
      .set('tel',body.tel )
      .set('detail',body.detail )
      .set('maintenance_time',body.maintenance_time )
      .set('status',body.status )
      .set('images', body.images)
      .set('type', body.type)
      .set('item_selected', body.item_selected)

      return this.http.post(url, params.toString(), { headers, observe: 'response' })
        .pipe(tap((res: HttpResponse<any>) => this.log('fetched data')))
        .catch((err: any) => Observable.of(false));

  }

  storeCollection(url: string, body: any){
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    let params: any
      params = new HttpParams()
      .set('user_id' ,body.user_id)
      .set('store_id',body.store_id)
      .set('company_name',body.company_name)
      .set('shop_name',body.shop_name)
      .set('address',body.address)
      .set('hope_collection_date',body.hope_collection_date)

      return this.http.post(url, params.toString(), { headers, observe: 'response' })
        .pipe(tap((res: HttpResponse<any>) => this.log('fetched data')))
        .catch((err: any) => Observable.of(false));

  }

  categoryOpt(url: string, body: any, opt: string){
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    let params: any
    if(opt === 'add'){
      params = new HttpParams()
        //.set('cat_no', body.cat_no)
        .set('cat_name', body.cat_name)
        .set('note', body.note)
        .set('user_id', body.user_id);

      return this.http.post(url, params.toString(), { headers, observe: 'response' })
        .pipe(tap((res: HttpResponse<any>) => this.log('fetched data')))
        .catch((err: any) => Observable.of(false));
    }
    else if(opt === 'edit'){
      params = new HttpParams()
        .set('cat_name', body.cat_name)
        .set('note', body.note)
        .set('user_id', body.user_id)
        .set('cat_no', body.cat_no)
         console.log(body.cat_no)
      return this.http.put(url+"/"+body.cat_no, params.toString(), { headers, observe: 'response' })
        .pipe(tap((res: HttpResponse<any>) => this.log('fetched data')))
        .catch((err: any) => Observable.of(false));
    }
    else if(opt === 'delete'){
      console.log('BODY :: ' + JSON.stringify(body))
      params = new HttpParams()
        .set('item_category_id', body.cat_no)
        .set('user_id', body.user_id);

      return this.http.post(url, params.toString(),{ headers, observe: 'response' })
        .pipe(tap((res: HttpResponse<any>) => this.log('fetched data')))
        .catch((err: any) => Observable.of(false));
    }
  }

  first_categoryOpt(url: string, body: any, opt: string){
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    let params: any
    if(opt === 'add'){
      params = new HttpParams()
        //.set('cat_no', body.cat_no)
        .set('first_cat_name', body.first_cat_name)
        .set('first_note', body.first_note)
        .set('user_id', body.user_id);

      return this.http.post(url, params.toString(), { headers, observe: 'response' })
        .pipe(tap((res: HttpResponse<any>) => this.log('fetched data')))
        .catch((err: any) => Observable.of(false));
    }
    else if(opt === 'edit'){
      params = new HttpParams()
        .set('first_cat_name', body.first_cat_name)
        .set('first_note', body.first_note)
        .set('user_id', body.user_id)
        .set('first_cat_no', body.first_cat_no)
         console.log(body.first_cat_no)
      return this.http.put(url+"/"+body.first_cat_no, params.toString(), { headers, observe: 'response' })
        .pipe(tap((res: HttpResponse<any>) => this.log('fetched data')))
        .catch((err: any) => Observable.of(false));
    }
    else if(opt === 'delete'){
      console.log('BODY :: ' + JSON.stringify(body))
      params = new HttpParams()
        .set('first_item_category_id', body.first_cat_no)
        .set('user_id', body.user_id);

      return this.http.post(url, params.toString(),{ headers, observe: 'response' })
        .pipe(tap((res: HttpResponse<any>) => this.log('fetched data')))
        .catch((err: any) => Observable.of(false));
    }
  }

  storeOpt(url: string, body: any, opt: string){
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    let params: any
    if(opt === 'add'){
      params = new HttpParams()
        .set('chain_no', body.chain_no)
        .set('chain_store_id', body.chain_store_id)
        .set('first_cat', body.first_cat)
        .set('second_cat', body.second_cat)
        .set('third_cat', body.third_cat)
        .set('ice_id', body.ice_id)
        .set('store_name', body.store_name)
        .set('tel', body.tel)
        .set('ship_name', body.ship_name)
        .set('tier', body.tier)
        .set('tier_ver2', body.tier_ver2)
        .set('area', body.area)
        .set('post_code', body.post_code)
        .set('pref', body.pref)
        .set('city', body.city)
        .set('address', body.address)
        .set('loc_type', body.loc_type)
        .set('square', body.square)
        .set('loc_station', body.loc_station)
        .set('population', body.population)
        .set('seller_type', body.seller_type)
        .set('closed_flg', body.closed_flg)
        .set('sa_flg', body.sa_flg)
        .set('round_flg', body.round_flg)
        .set('round_finish_date', body.round_finish_date)
        .set('report_flg', body.report_flg)
        .set('cr_type', body.cr_type)
        .set('company_in_charge', body.company_in_charge)
        .set('rounder', body.rounder)
        .set('corner', body.corner)
        .set('ctrl_flg', body.ctrl_flg)
        .set('utencil_type', body.utencil_type)
        .set('wms_id', body.wms_id)
        .set('created_by', body.created_by)
        .set('tz_zero_padding', body.tz_zero_padding)
        .set('retail_tier', body.retail_tier)
        .set('remark1', body.remark1)
        .set('remark2', body.remark2)
        .set('channel', body.channel)


      return this.http.post(url, params.toString(), { headers, observe: 'response' })
        .pipe(tap((res: HttpResponse<any>) => this.log('fetched data')))
        .catch((err: any) => Observable.of(false));
    }
    else if(opt === 'edit'){
      params = new HttpParams()
        .set('id', body.id)
        .set('chain_no', body.chain_no)
        .set('chain_store_id', body.chain_store_id)
        .set('first_cat', body.first_cat)
        .set('second_cat', body.second_cat)
        .set('third_cat', body.third_cat)
        .set('ice_id', body.ice_id)
        .set('store_name', body.store_name)
        .set('tel', body.tel)
        .set('ship_name', body.ship_name)
        .set('tier', body.tier)
        .set('tier_ver2', body.tier_ver2)
        .set('area', body.area)
        .set('post_code', body.post_code)
        .set('pref', body.pref)
        .set('city', body.city)
        .set('address', body.address)
        .set('loc_type', body.loc_type)
        .set('square', body.square)
        .set('loc_station', body.loc_station)
        .set('population', body.population)
        .set('seller_type', body.seller_type)
        .set('closed_flg', body.closed_flg)
        .set('sa_flg', body.sa_flg)
        .set('round_flg', body.round_flg)
        .set('round_finish_date', body.round_finish_date)
        .set('report_flg', body.report_flg)
        .set('cr_type', body.cr_type)
        .set('company_in_charge', body.company_in_charge)
        .set('rounder', body.rounder)
        .set('corner', body.corner)
        .set('ctrl_flg', body.ctrl_flg)
        .set('utencil_type', body.utencil_type)
        .set('wms_id', body.wms_id)
        .set('updated_by', body.updated_by)
        .set('tz_zero_padding', body.tz_zero_padding)
        .set('retail_tier', body.retail_tier)
        .set('remark1', body.remark1)
        .set('remark2', body.remark2)
        .set('channel', body.channel)

        console.log('body update :: ' + JSON.stringify(body))
      return this.http.put(url, params.toString(), { headers, observe: 'response' })
        .pipe(tap((res: HttpResponse<any>) => this.log('fetched data')))
        .catch((err: any) => Observable.of(false));
    }
    else if(opt === 'delete'){
      console.log('BODY :: ' + JSON.stringify(body))
      params = new HttpParams()
        .set('id', body.id)
        .set('store_no', body.store_no)
        .set('deleted_by', body.deleted_by);

      return this.http.post(url, params.toString(), { headers, observe: 'response' })
        .pipe(tap((res: HttpResponse<any>) => this.log('fetched data')))
        .catch((err: any) => Observable.of(false));
    }
  }

  storeSecondCatOpt(url: string, body: any, opt: string){
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    let params: any
    if(opt === 'add'){
      params = new HttpParams()
        .set('name', body.name)
        .set('note', body.note)
        .set('user_id', body.user_id);

      return this.http.post(url, params.toString(), { headers, observe: 'response' })
        .pipe(tap((res: HttpResponse<any>) => this.log('fetched data')))
        .catch((err: any) => Observable.of(false));
    }
    else if(opt === 'edit'){
      params = new HttpParams()
        .set('name', body.name)
        .set('note', body.note)
        .set('user_id', body.user_id)
        .set('id', body.id)
      return this.http.put(url+"/"+body.id, params.toString(), { headers, observe: 'response' })
        .pipe(tap((res: HttpResponse<any>) => this.log('fetched data')))
        .catch((err: any) => Observable.of(false));
    }
    else if(opt === 'delete'){
      console.log('BODY :: ' + JSON.stringify(body))
      params = new HttpParams()
        .set('id', body.id)
        .set('user_id', body.user_id);

      return this.http.post(url, params.toString(),{ headers, observe: 'response' })
        .pipe(tap((res: HttpResponse<any>) => this.log('fetched data')))
        .catch((err: any) => Observable.of(false));
    }
  }

  storeThirdCatOpt(url: string, body: any, opt: string){
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    let params: any
    if(opt === 'add'){
      params = new HttpParams()
        .set('name', body.name)
        .set('note', body.note)
        .set('user_id', body.user_id);

      return this.http.post(url, params.toString(), { headers, observe: 'response' })
        .pipe(tap((res: HttpResponse<any>) => this.log('fetched data')))
        .catch((err: any) => Observable.of(false));
    }
    else if(opt === 'edit'){
      params = new HttpParams()
        .set('name', body.name)
        .set('note', body.note)
        .set('user_id', body.user_id)
        .set('id', body.id)
      return this.http.put(url+"/"+body.id, params.toString(), { headers, observe: 'response' })
        .pipe(tap((res: HttpResponse<any>) => this.log('fetched data')))
        .catch((err: any) => Observable.of(false));
    }
    else if(opt === 'delete'){
      console.log('BODY :: ' + JSON.stringify(body))
      params = new HttpParams()
        .set('id', body.id)
        .set('user_id', body.user_id);

      return this.http.post(url, params.toString(),{ headers, observe: 'response' })
        .pipe(tap((res: HttpResponse<any>) => this.log('fetched data')))
        .catch((err: any) => Observable.of(false));
    }
  }

  carrierOpt(url: string, body: any, opt: string){
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    let params: any
    if(opt === 'add'){
      params = new HttpParams()
        .set('created_by', body.created_by)
        .set('unique_id', body.unique_id)
        .set('carrier_id', body.carrier_id)
        .set('ice_id', body.ice_id)
        .set('carrier_no', body.carrier_no)
        .set('store_code', body.store_code)
        .set('store_name', body.store_name)
        .set('store_name_kana', body.store_name_kana)
        .set('first_cat', body.first_cat)
        .set('second_cat', body.second_cat)
        .set('third_cat', body.third_cat)
        .set('ship_name', body.ship_name)
        .set('area', body.area)
        .set('pref', body.pref)
        .set('city', body.city)
        .set('post_code', body.post_code)
        .set('address', body.address)
        .set('tel', body.tel)
        .set('cr_type', body.cr_type)
        .set('first_agent_name', body.first_agent_name)
        .set('company_name', body.company_name)
        .set('owners_evaluation', body.owners_evaluation)
        .set('loc_type', body.loc_type)
        .set('ho_name', body.ho_name)
        .set('sq_rank', body.sq_rank)
        .set('ts_rank', body.ts_rank)
        .set('tt_type', body.tt_type)
        .set('training', body.training)
        .set('remark1', body.remark1)
        .set('remark2', body.remark2)
        .set('loc_station', body.loc_station)
        .set('population', body.population)
        .set('closed_flg', body.closed_flg)
        .set('sa_flg', body.sa_flg)
        .set('retail_tier', body.retail_tier)


      return this.http.post(url, params.toString(), { headers, observe: 'response' })
        .pipe(tap((res: HttpResponse<any>) => this.log('fetched data')))
        .catch((err: any) => Observable.of(false));
    }
    else if(opt === 'edit'){
      params = new HttpParams()
        .set('id', body.id)
        .set('updated_by', body.updated_by)
        .set('unique_id', body.unique_id)
        .set('carrier_id', body.carrier_id)
        .set('ice_id', body.ice_id)
        .set('carrier_no', body.carrier_no)
        .set('store_code', body.store_code)
        .set('store_name', body.store_name)
        .set('store_name_kana', body.store_name_kana)
        .set('first_cat', body.first_cat)
        .set('second_cat', body.second_cat)
        .set('third_cat', body.third_cat)
        .set('ship_name', body.ship_name)
        .set('area', body.area)
        .set('pref', body.pref)
        .set('city', body.city)
        .set('post_code', body.post_code)
        .set('address', body.address)
        .set('tel', body.tel)
        .set('cr_type', body.cr_type)
        .set('first_agent_name', body.first_agent_name)
        .set('company_name', body.company_name)
        .set('owners_evaluation', body.owners_evaluation)
        .set('loc_type', body.loc_type)
        .set('ho_name', body.ho_name)
        .set('sq_rank', body.sq_rank)
        .set('ts_rank', body.ts_rank)
        .set('tt_type', body.tt_type)
        .set('training', body.training)
        .set('remark1', body.remark1)
        .set('remark2', body.remark2)
        .set('loc_station', body.loc_station)
        .set('population', body.population)
        .set('closed_flg', body.closed_flg)
        .set('sa_flg', body.sa_flg)
        .set('retail_tier', body.retail_tier)


        console.log('body update :: ' + JSON.stringify(body))
      return this.http.put(url, params.toString(), { headers, observe: 'response' })
        .pipe(tap((res: HttpResponse<any>) => this.log('fetched data')))
        .catch((err: any) => Observable.of(false));
    }
    else if(opt === 'delete'){
      console.log('BODY :: ' + JSON.stringify(body))
      params = new HttpParams()
        .set('id', body.id)
        .set('store_no', body.store_no)
        .set('deleted_by', body.deleted_by);

      return this.http.post(url, params.toString(), { headers, observe: 'response' })
        .pipe(tap((res: HttpResponse<any>) => this.log('fetched data')))
        .catch((err: any) => Observable.of(false));
    }
  }

  carrierFirstCatOpt(url: string, body: any, opt: string){
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    let params: any
    if(opt === 'add'){
      params = new HttpParams()
        .set('name', body.name)
        .set('note', body.note)
        .set('user_id', body.user_id);

      return this.http.post(url, params.toString(), { headers, observe: 'response' })
        .pipe(tap((res: HttpResponse<any>) => this.log('fetched data')))
        .catch((err: any) => Observable.of(false));
    }
    else if(opt === 'edit'){
      params = new HttpParams()
        .set('name', body.name)
        .set('note', body.note)
        .set('user_id', body.user_id)
        .set('id', body.id)
      return this.http.put(url+"/"+body.id, params.toString(), { headers, observe: 'response' })
        .pipe(tap((res: HttpResponse<any>) => this.log('fetched data')))
        .catch((err: any) => Observable.of(false));
    }
    else if(opt === 'delete'){
      console.log('BODY :: ' + JSON.stringify(body))
      params = new HttpParams()
        .set('id', body.id)
        .set('user_id', body.user_id);

      return this.http.post(url, params.toString(),{ headers, observe: 'response' })
        .pipe(tap((res: HttpResponse<any>) => this.log('fetched data')))
        .catch((err: any) => Observable.of(false));
    }
  }

  carrierSecondCatOpt(url: string, body: any, opt: string){
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    let params: any
    if(opt === 'add'){
      params = new HttpParams()
        .set('name', body.name)
        .set('note', body.note)
        .set('user_id', body.user_id);

      return this.http.post(url, params.toString(), { headers, observe: 'response' })
        .pipe(tap((res: HttpResponse<any>) => this.log('fetched data')))
        .catch((err: any) => Observable.of(false));
    }
    else if(opt === 'edit'){
      params = new HttpParams()
        .set('name', body.name)
        .set('note', body.note)
        .set('user_id', body.user_id)
        .set('id', body.id)
      return this.http.put(url+"/"+body.id, params.toString(), { headers, observe: 'response' })
        .pipe(tap((res: HttpResponse<any>) => this.log('fetched data')))
        .catch((err: any) => Observable.of(false));
    }
    else if(opt === 'delete'){
      console.log('BODY :: ' + JSON.stringify(body))
      params = new HttpParams()
        .set('id', body.id)
        .set('user_id', body.user_id);

      return this.http.post(url, params.toString(),{ headers, observe: 'response' })
        .pipe(tap((res: HttpResponse<any>) => this.log('fetched data')))
        .catch((err: any) => Observable.of(false));
    }
  }

  carrierThirdCatOpt(url: string, body: any, opt: string){
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    let params: any
    if(opt === 'add'){
      params = new HttpParams()
        .set('name', body.name)
        .set('note', body.note)
        .set('user_id', body.user_id);

      return this.http.post(url, params.toString(), { headers, observe: 'response' })
        .pipe(tap((res: HttpResponse<any>) => this.log('fetched data')))
        .catch((err: any) => Observable.of(false));
    }
    else if(opt === 'edit'){
      params = new HttpParams()
        .set('name', body.name)
        .set('note', body.note)
        .set('user_id', body.user_id)
        .set('id', body.id)
      return this.http.put(url+"/"+body.id, params.toString(), { headers, observe: 'response' })
        .pipe(tap((res: HttpResponse<any>) => this.log('fetched data')))
        .catch((err: any) => Observable.of(false));
    }
    else if(opt === 'delete'){
      console.log('BODY :: ' + JSON.stringify(body))
      params = new HttpParams()
        .set('id', body.id)
        .set('user_id', body.user_id);

      return this.http.post(url, params.toString(),{ headers, observe: 'response' })
        .pipe(tap((res: HttpResponse<any>) => this.log('fetched data')))
        .catch((err: any) => Observable.of(false));
    }
  }

  venderOpt(url: string, body: any, opt: string){
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    let params: any

    if(opt === 'add'){
      params = new HttpParams()
        .set('name', body.name)
        .set('address', body.address)
        .set('tel', body.tel)
        .set('filename', body.filename)
        .set('created_by', body.created_by);

      return this.http.post(url, params.toString(), { headers, observe: 'response' })
        .pipe(tap((res: HttpResponse<any>) => this.log('fetched data')))
        .catch((err: any) => Observable.of(false));
    }

    if(opt === 'edit'){
      console.log(body)
      params = new HttpParams()
      .set('id', body.id)
      .set('name', body.name)
      .set('address', body.address)
      .set('filename', body.filename)
      .set('tel', body.tel)
      .set('updated_by', body.updated_by)

      return this.http.put(url, params.toString(), { headers, observe: 'response' })
      .pipe(tap((res: HttpResponse<any>) => this.log('fetched data')))
      .catch((err: any) => Observable.of(false));
    }

    else if(opt === 'delete'){
      params = new HttpParams()
        .set('id', body.id)
        .set('deleted_by', body.deleted_by);

      return this.http.post(url, params.toString(),{ headers, observe: 'response' })
        .pipe(tap((res: HttpResponse<any>) => this.log('fetched data')))
        .catch((err: any) => Observable.of(false));
    }
  }

  postRelation(url: string, body: any){
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    let params = new HttpParams()
      .set('inquiry', body.inquiry)
      .set('order_ref_no', body.order)
      .set('collection', body.collection)
      .set('maintenance', body.maintenance)
      .set('created_by', body.created_by);

    return this.http.post(url, params.toString(), { headers, observe: 'response' })
      .pipe(tap((res: HttpResponse<any>) => this.log('fetched data')))
      .catch((err: any) => Observable.of(false));
  }

  putRelation(url: string, body: any){
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    let params = new HttpParams()
      .set('inquiry', body.inquiry)
      .set('order_ref_no', body.order)
      .set('collection', body.collection)
      .set('maintenance', body.maintenance)
      .set('created_by', body.created_by)
      .set('id', body.id);

    return this.http.put(url, params.toString(), { headers, observe: 'response' })
      .pipe(tap((res: HttpResponse<any>) => this.log('fetched data')))
      .catch((err: any) => Observable.of(false));
  }

  saveTask(url: string, body: any){
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    let params = new HttpParams()
      .set('head', body.head)
      .set('reference', body.reference)
      .set('type', body.type)
      .set('user_type', body.user_type)
      .set('user', body.user)
      .set('level', body.level)
      .set('user_id', body.user_id)
      .set('cc_view', body.cc_view)
      .set('hd_view', body.hd_view)
      .set('wh_view', body.wh_view)
      .set('rr_view', body.rr_view)
      .set('cc_cancel', body.cc_cancel ? body.cc_cancel: 0)
      .set('wh_cancel', body.wh_cancel ? body.wh_cancel: 0);

    return this.http.post(url, params.toString(), { headers, observe: 'response' })
      .pipe(tap((res: HttpResponse<any>) => this.log('fetched data')))
      .catch((err: any) => Observable.of(false));
  }

  deleteTask(url: string, body: any){
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    let params = new HttpParams()
        .set('remarks', body.remarks)
        .set('user_id', body.user_id);

      return this.http.post(url + body.task_id, params.toString(),{ headers, observe: 'response' })
        .pipe(tap((res: HttpResponse<any>) => this.log('fetched data')))
        .catch((err: any) => Observable.of(false));
  }

  saveCSV(url: string, body: any){
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    let params = new HttpParams()
    .set('ids', body);

    return this.http.post(url, params.toString(), { headers, observe: 'response' })
      .pipe(tap((res: HttpResponse<any>) => this.log('fetched data')))
      .catch((err: any) => Observable.of(false));
  }

  auth(url: string, body: any) {
    return this.http.post<any>(url, body).pipe(
      map(user => {
        if (user && user.token) {
          localStorage.setItem('currentUser', JSON.stringify(user));
        }

        return user;
      })
    )
  }

  get(url: string, _id: string | null) {

    url += _id != null ? _id : '';
    if (_id == null) {
      console.log(url)
      return this.http.get<any[]>(url).pipe(
        tap(any => this.log('fetched data')),
        catchError(this.handleError<any>('get', []))
      )
    } else {
      return this.http.get<any>(url).pipe(
        tap(_ => this.log('fetched data by id')),
        catchError(this.handleError<any>(`get ${_id} error`))
      )
    }
  }

  post(url: string, body: any) {
    return this.http.post<any>(url, body).pipe(
      tap((result: any) => this.log(`post data from ${url}`)),
      catchError(this.handleError<any>('post error'))
    )
  }

  delete(url: string, data: any | number) {
    const id = typeof data === 'number' ? data : data._id;
    url += id;
    return this.http.delete<any>(url).pipe(
      tap(_ => this.log(`delete data from ${url}`)),
      catchError(this.handleError<any>('delete error'))
    )
  }

  patch(url: string, _id: string, body: any) {
    url += _id;
    return this.http.patch(url, body).pipe(
      tap(_ => this.log(`patch data from ${url}`)),
      catchError(this.handleError<any>('patch error'))
    )
  }

  put(url: string, body: any) {
    return this.http.put<any>(url, body).pipe(
      tap((result: any) => this.log(`post data from ${url}`)),
      catchError(this.handleError<any>('post error'))
    )
  }

  request(url: string, data: any) {
    const req = new HttpRequest('POST', url, data, {
      reportProgress: true,
    });

    return this.http.request(req);
  }

  appendPatch(column: string, value: any): any {
    return { 'propName': column, 'value': value }
  }

  showSuccessPostMessage() {
    this.messageService.openSnackBar('Data has been successfully added');
  }

  showSuccessDeleteMessage() {
    this.messageService.openSnackBar('Data has been successfully deleted');
  }

  showSuccessPatchMessage() {
    this.messageService.openSnackBar('Data has been successfully updated');
  }

  showErrorFillMessage() {
    this.messageService.openSnackBar('Please fill the required fields to continue.');
  }

  showOtherMessage(message: string) {
    this.messageService.openSnackBar(message);
  }

  showAtleastMessage(){
    this.messageService.openSnackBar("パスワードは最低１文字以上の半角英数字と特殊文字を含む8文字以上である必要があります。");
    //password must be had 8 didits consisting of
    //at least 1 half-width alphabetical and numeric as well as special characters.
  }

  showDiffMessage(){
    this.messageService.openSnackBar("確認用のパスワードと一致しません！");
    //does not match password and password-confirm field.
  }

  showAddSuccessMessage(item:any,qty:any){
    this.messageService.openSnackBar("【" + item + "】" + "を" + qty + "追加しました！");
  }

  showInsufficientMessage(){
    this.messageService.openSnackBar("入力値が在庫を上回っています！");
  }

  showIncorrectAmountMessage(){
    this.messageService.openSnackBar("正しい値を入力してください。");
  }

}

export const COMPANY_NAME = "G-Retail";

export const COMPANY_NAME_SUB = "";


// export const HTTP_API_URL = "https://gcrms-dev-api.appspot.com/";
export const HTTP_API_URL = "https://g-retail-dev-api2.df.r.appspot.com/";

// export const HTTP_API_URL = "http://localhost:8090/";
//export const HTTP_API_URL = "http://192.168.1.139:8090/";
// export const HTTP_API_URL = "https://g-retail-stg-api.appspot.com/";
//export const HTTP_API_URL = "https://g-retail-prod-api.appspot.com/";

export const usersUrl = HTTP_API_URL + 'users/';
export const clientUsersUrl = HTTP_API_URL + 'users/';
export const imagesUrl = HTTP_API_URL + 'images/';
export const emailSentUrl = HTTP_API_URL + 'v1/auth/resetpassword/';
export const resetverify = HTTP_API_URL + 'v1/auth/resetverify?url=';
export const passUpdateUrl = HTTP_API_URL + 'v1/auth/reset';
export const adminUsersUrl = HTTP_API_URL + 'admin/';
export const authUrl = '/api/authenticate';
export const branchesUrl = HTTP_API_URL + 'branches/';

export const setOrderUrl = HTTP_API_URL + 'v1/order/';
export const getOrdersUrl = HTTP_API_URL + 'v1/orders/';
export const getOrderedItemsUrl = HTTP_API_URL + 'v1/ordered_items/';
export const getOrderRecordsUrl = HTTP_API_URL + 'v1/ordered_records/';
export const allOrdersUrl = HTTP_API_URL + 'v1/ordered_records?';
export const updateOrder = HTTP_API_URL + 'v1/orderupdate'

export const inquiryUrl = HTTP_API_URL + 'v1/inquiry/';
export const getInqUrl = HTTP_API_URL + 'v1/inquiries/';
export const storeUrl = HTTP_API_URL + 'v1/store/';
export const storesUrl = HTTP_API_URL + 'v1/stores/';
export const autUserhUrl = HTTP_API_URL + 'v1/auth/login';

export const itemUrl = HTTP_API_URL + 'v1/item';
export const itemsUrl = HTTP_API_URL + 'v1/items';
export const addItemUrl = HTTP_API_URL + 'v1/item';
export const delItemUrl = HTTP_API_URL + 'v1/item/delete';
export const chainsUrl = HTTP_API_URL + 'v1/chains';
export const addChainUrl = HTTP_API_URL + 'v1/chain';
export const delChainUrl = HTTP_API_URL + 'v1/chain/delete';

export const first_categoriesUrl = HTTP_API_URL + 'v1/first_categories';
export const first_addCategoryUrl = HTTP_API_URL + 'v1/first_category';
export const first_delCategoryUrl = HTTP_API_URL + 'v1/first_category/delete';

export const categoriesUrl = HTTP_API_URL + 'v1/categories';
export const addCategoryUrl = HTTP_API_URL + 'v1/category';
export const delCategoryUrl = HTTP_API_URL + 'v1/category/delete';
export const getStoresUrl = HTTP_API_URL + 'v1/stores';
export const storeOptUrl = HTTP_API_URL + 'v1/store';
export const delStoretUrl = HTTP_API_URL + 'v1/store/delete';
export const userUrl = HTTP_API_URL + 'v1/users';
export const userOptUrl = HTTP_API_URL + 'v1/user';
export const delUserUrl = HTTP_API_URL + 'v1/user/delete';
export const inboundInquiry = HTTP_API_URL + 'v1/inboundinquiries?';
export const inboundOrders = HTTP_API_URL + 'v1/orders';
export const shipRecord = HTTP_API_URL + 'v1/shiprecord';
export const fileUploadUrl = HTTP_API_URL + 'v1/user/files';
export const saveRelation = HTTP_API_URL + 'v1/relation'
export const saveTaskUrl = HTTP_API_URL + 'v1/taskrecord'
export const ccInquiry = HTTP_API_URL + 'v1/ccinquiry'
export const ccOrder = HTTP_API_URL + 'v1/ccorder'
export const hdInquiry = HTTP_API_URL + 'v1/hdinquiry'
export const hdOrder = HTTP_API_URL + 'v1/hdorder'
export const whInquiry = HTTP_API_URL + 'v1/whinquiry'
export const whOrder = HTTP_API_URL + 'v1/whorder'
export const filterByRefTaskUrl = HTTP_API_URL + 'v1/taskrecords/findByFilter?reference_number='
export const deleteTaskUrl = HTTP_API_URL + 'v1/taskrecord/'
export const hdDelete = HTTP_API_URL + 'v1/hddelete/'
export const ccDelete = HTTP_API_URL + 'v1/ccdelete/'
export const whDelete = HTTP_API_URL + 'v1/whdelete/'
export const multiSave = HTTP_API_URL + 'v1/multisave/'
export const venderUrl =  HTTP_API_URL + 'v1/venders';
export const addVenderUrl =  HTTP_API_URL + 'v1/vender';
export const delVenderUrl =  HTTP_API_URL + 'v1/vender/delete';
export const collectionUrl = HTTP_API_URL + 'v1/collection';
export const getCollectionUrl = HTTP_API_URL + 'v1/collections';
export const inboundCollectionUrl = HTTP_API_URL + 'v1/inboundcollection?';
export const maintenanceUrl =  HTTP_API_URL + 'v1/maintenance';
export const getTaskRecords = HTTP_API_URL + 'v1/gettask/';
export const maintenanceTaskrecordsUrl = HTTP_API_URL + 'v1/maintenance/';
export const getMaintenanceUrl = HTTP_API_URL + 'v1/maintenances';
export const inboundMaintenanceUrl = HTTP_API_URL + 'v1/inboundmaintenance?';
export const multipleStockUpdate = HTTP_API_URL + 'v1/item/multiple-stock-update';
export const systemUrl = HTTP_API_URL + 'v1/system';
export const updateDetail = HTTP_API_URL + 'v1/update/detail'
export const itemPaginationUrl = HTTP_API_URL + 'v1/item/pagination';
export const fetchStores = HTTP_API_URL + 'v1/getstores'
export const userPaginationUrl = HTTP_API_URL + 'v1/user/pagination';

export const badgeCoutUrl = HTTP_API_URL + 'v1/badgecount/'
export const getItemsForMaintenance = HTTP_API_URL + 'v1/items/get-item-for-maintenance'

export const uploadFilestUrl = HTTP_API_URL + 'v1/upload_files';
export const carrierOptUrl  = HTTP_API_URL + 'v1/carrier'
export const delCarrierUrl = HTTP_API_URL + 'v1/carrier/delete';
export const fetchCarriers = HTTP_API_URL + 'v1/carriers'
export const importCSV = HTTP_API_URL + 'v1/import-csv'

export const CarrierFirstCatUrl = HTTP_API_URL + 'v1/carrier_first_cat'
export const CarrierSecondCatUrl = HTTP_API_URL + 'v1/carrier_second_cat'
export const CarrierThirdCatUrl = HTTP_API_URL + 'v1/carrier_third_cat'

export const StoreSecondCatUrl = HTTP_API_URL + 'v1/store_second_cat'
export const StoreThirdCatUrl = HTTP_API_URL + 'v1/store_third_cat'

export enum Request {
  DELETE, GET, PUT, PATCH, POST
}
